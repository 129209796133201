import "./liveSplitComplete.css";

function LiveSplitComplete(){
    return (
        <div>
            Completed!
        </div>
    )
}

export default LiveSplitComplete