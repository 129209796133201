import React from 'react';
import {
  createBrowserRouter,
  RouterProvider,
  Route,
} from 'react-router-dom';

import Root from './root';
import ErrorPage from './errorPage';
import LiveSplitView from './liveSplitView';
import LiveSplitStart from './liveSplitStart';
import LiveSplitComplete from './liveSplitComplete';
import LivesplitTest from './livesplitTest';
import AddFriendsView from './addFriendsView';
import SplitStatusView from './splitstatus';


const router = createBrowserRouter([
  {
    path:"/",
    element:<Root />,
    errorElement: <ErrorPage />,
    children: [
      {
        path: "livesplit/",
        element: <LiveSplitStart />,
      },  
      {
        path: "livesplit/status",
        element: <SplitStatusView />
      },          
      {
        path: "livesplit/:livesplitid",
        element: <LiveSplitView />,
      },
      {
        path: "livesplit/complete",
        element: <LiveSplitComplete />,
      },
      {
        path: "livesplit/test",
        element: <LivesplitTest />
      },
      {
        path: "livesplit/addFriend/:livesplitid",
        element: <AddFriendsView />
      },
    ],    
  }
])

function App() {
  const [data, setData] = React.useState(null);

  return (
    <RouterProvider router={router} />
  );
}

export default App;