import {Grid, TextField, Button} from '@mui/material';
import "./liveSplitStart.css";

function LiveSplitStart(){
    return (
        <Grid container direction="row" spacing={0} alignItems="center">
            <Grid item xs={12}>
                SCAN A QR Code <br/>
                or enter Split code here <br/>
            </Grid>
            <Grid item xs={6}>
                <TextField id="outlined-basic" label="Outlined" variant="outlined" />
            </Grid>
            <Grid item xs={6}>
                <Button variant="contained">Submit</Button>
            </Grid>
        </Grid>
    )
}

export default LiveSplitStart