import {Container} from '@mui/material';
import { Outlet, Link } from "react-router-dom";
import "./root.css"

function Root(){
    return (
        <div className='rootMain'>
            <Container maxWidth="sm">
                <Outlet />
            </Container>
        </div>

    )
}

export default Root