import React, {Component}  from 'react';
import {Grid, Paper, Box, Avatar, Link, AvatarGroup} from '@mui/material';
import { deepOrange, deepPurple } from '@mui/material/colors';
import { useParams } from "react-router-dom";
import io from 'socket.io-client';
import { ReactComponent as AppStoreLogo } from './images/app_store_black.svg';
import background from './images/Splash_Screen.png';
import logo from './images/split_logo_live.png';
import logoClean from './images/CleanLogoWithName.png';
import logoSelect from './images/Group_34.png';
import './liveSplitView.css';
import * as DEBUG from './debug'; 

// console.log("Just before socket init");
const socket = io();


class LiveSplitView extends Component{
	constructor(props){
		super(props);

		this.state = {
			itemList : [],
			isConnected:false,
			myId:null,
			room:null,
			listAttendees:null,
			listColors:null,
			wasInSplit:false,
			splitterName:null,
			hasCompletedSplit:false,
		};
	}


	componentDidMount = () =>{
		DEBUG.consoleFunctionSystem("componentDidMount");
		
		let urlStr = window.location.href;
		let myId = urlStr.split("livesplit/")[1].split("?myId=")[1];
		let room = urlStr.split("livesplit/")[1].split("?myId=")[0];


		// This happens on first connection of the webclient to the socket server.
		// Also happens if the IOS app disconnect i.e. user navigated back to some page, and send back the list
		// in case the users added/removed items or attendees.

        socket.on('initList', (obj) => {
			DEBUG.consoleFunctionAPI("initlist");
            console.log("initList ", obj);
			// room
			// splitterName
			// attendees		--> externalID -> initials
			// attendeesColors --> hex Colors of attendees
			// message
			//	 id
			//	 parentId
			//	 name
			//	 assignees		--> externalIDs


			// 01/14 -- Commenting the code that we had before the change in handling of disconnect.
			//
			// if(!this.state.listAttendees){
			// 	console.log("initilization state variable");

			// 	this.setState({
			// 		splitterName: obj.splitterName,
			// 		itemList : obj.message,
			// 		listAttendees : obj.attendees,
			// 		listColors : obj.attendeesColors
			// 	});
			// }


			// 01/14 -- New code for handling of disonnect. We apply all initList
			this.setState({
				splitterName: obj.splitterName,
				itemList : obj.message,
				listAttendees : obj.attendees,
				listColors : obj.attendeesColors
			});			

            console.log("done initList");
        });

        socket.on('connect', () => {
            DEBUG.consoleFunctionAPI("connect");

			if(!this.state.isConnected){
				console.log("Not connected, joining room");

				socket.emit('joinRoom', this.state.room, this.state.myId, (response) => {
					console.log(response);
	
					this.setState({
						isConnected:response === "success",
					});				
				});
			}
        });	
		
        socket.on('disconnect', () => {
            DEBUG.consoleFunctionAPI("disconnect");

			this.setState({
				isConnected:false,
			});
            console.log('disconnected');
        });

		socket.on('closeAndLeave', ()=>{
			DEBUG.consoleFunctionAPI("closeAndLeave");

			this.setState({
				isConnected:false,
				wasInSplit:true,
			});

			socket.disconnect();
		});

        socket.on('liveUpdate', (obj) => {
			DEBUG.consoleFunctionAPI("liveupdate");
            console.log("liveUpdate ", obj);

			// room
			// message
				// itemId
				// parentId
				// userId	--> ExternalID
				// action --> add / remove / addAll / removeAll / complete
				// timestamp

            // create a copy of existing item list
            // let list = {...itemList}; 

			if(obj.message.action !== "complete"){
				console.log("state.itemList ", this.state.itemList);
				let list = JSON.parse(JSON.stringify(this.state.itemList));
				console.log("list ", list);
	
				let updatedItem = list.find(e=>e.itemId === obj.message.itemId);
				console.log("updatedItem ", updatedItem);
				let updatedItemIndex = list.findIndex(e=>e.itemId === obj.message.itemId);
				console.log("updatedItemIndex ", updatedItemIndex);
	
				switch(obj.message.action){
					case "addAll":
						for (let attendee in this.state.listAttendees){
							if(updatedItem.assignees.indexOf(attendee) === -1){
								updatedItem.assignees.push(attendee);
							}
						}
						break;
					case "removeAll":
						updatedItem.assignees = [];
						break;
					case "add":
						updatedItem.assignees.push(obj.message.userId);
						break;
					case "remove":
						let index = updatedItem.assignees.findIndex(e=>e === obj.message.userId);
						if(index > -1){
							updatedItem.assignees.splice(index,1);
						}
						break;
					default:
						break;
				}
	
				list[updatedItemIndex] = updatedItem;
				
				this.setState({
					itemList:list,
				});
			}

            console.log("done liveUpdate");
        });	

		this.setState({
			myId:myId,
			room:room,			
		})
	}

	componentDidUpdate = (prevProps, prevState) =>{
		DEBUG.consoleFunctionSystem("componentDidUpdate");
		// if(!this.state.loading && prevState.loading && this.props.match.params.hasOwnProperty("id")){

		// }
	}

	componentWillUnmount() {
		DEBUG.consoleFunctionSystem("componentWillUnmount");
		socket.off("initList");
		socket.off("liveUpdate");
		// socket.disconnect();
		// this.setState({
		// 	isConnected:false,
		// })
	  }

	handleDoneClick = (e) => {
		DEBUG.consoleFunctionSystem("HandleDoneClick");

		let obj = {
			room:this.state.room,
			message:{
				userId: this.state.myId,
				itemId: "",
				parentId: "",
				action: "complete",
				timestamp: Date.parse(new Date())/1000,
			}
        };

		console.log("Completing split - ", obj);

        socket.emit("liveUpdate",obj);

		this.setState({
			hasCompletedSplit:true,
		});
	}

    handleClick = (e, itemId, itemIndex) => {
		DEBUG.consoleFunctionSystem("HandleClick");

		console.log("handleClick ",itemId,itemIndex);

        let updatedList = [...this.state.itemList];    // make a copy of the existing list
		console.log("updatedList ", updatedList);

        let updatedItem = {...this.state.itemList[itemIndex]}; // make a copy of the item that will be updated
		console.log("updatedItem ", updatedItem);

        let action = "";

		// does the list of assignees have the current user?
		let assigneeIndex = updatedItem.assignees.findIndex(element => element === this.state.myId);

		// if not undefined, we found our user, we remove them
		if(assigneeIndex > -1){
            updatedItem.assignees.splice(assigneeIndex,1);
            action = "remove";
		}
		else{
            updatedItem.assignees.push(this.state.myId);
            action = "add";
		}

        let obj = {
			room:this.state.room,
			message:{
				itemId: itemId,
				parentId: updatedItem.parentId,
				userId: this.state.myId,
				action: action,
				timestamp: Date.parse(new Date())/1000,
			}
        };

		console.log("liveupdated obj ", obj);

        socket.emit("liveUpdate",obj);

		updatedList[itemIndex]= updatedItem;
		console.log("updatedList ", updatedList);

		this.setState({
			itemList:updatedList,
		})
        
		console.log("handleClick DONE");
    }	


	render(){
		DEBUG.consoleFunctionSystem("Render");

		return (
			<>
				{
					this.state.isConnected && !this.state.hasCompletedSplit?
					<div className="mainPage">
                        <img src={logoSelect} className="topLogo"/>

						<div className="title">
							Welcome to {this.state.splitterName?this.state.splitterName+"'s":""} Live Split!
						</div>

						<div className='instructions'>
							Tap each item to select what you ordered. If an item was shared, it can be selected by multiple people
						</div>

						<Grid container spacing={1} className="gridClass">
							{this.state.itemList.map((item, index) =>(
								<Grid item xs={12} key={'row_'+item.itemId}>
									<Box
										sx={{
										bgcolor: 'background.paper',
										boxShadow: 1,
										borderRadius: 2,
										p: 2,
										minWidth: 300,
										textAlign:'left',
										}}

										className='cardTest'

										onClick={(e)=>this.handleClick(e,item.itemId, index)}
									>
										<Box sx={{ color: 'text.secondary', fontFamily:'Avenir-Medium', fontSize:'16px'}}>{item.name}</Box>
										<div className="avatarDisplay">
											<AvatarGroup max={4}>
												{item.assignees.length > 0 && item.assignees.map((assignee,index)=>(	
													<Avatar key={'avatar_'+assignee}
															sx={{ bgcolor: '#'+this.state.listColors[assignee],
															fontSize:`${this.state.listAttendees[assignee].length > 2 ? "1.rem" : "1.25rem"}` 
															}}>
																{this.state.listAttendees[assignee]}
													</Avatar>
												))
												}
											</AvatarGroup>
										</div>
									</Box>
								</Grid>
							))}
						</Grid>

                        <footer class="button-bar" onClick={this.handleDoneClick}>
                            <button class="button">Done</button>
                        </footer>						
					</div>
					:
                    <div style={{
                        backgroundImage:`url(${background})`,
                        height:'100vh', 
                        backgroundRepeat: 'no-repeat',
                        backgroundSize: 'cover',
                        backgroundPosition: 'center'
                        }}>
							<img src={logoClean} className="topLogoSplash"/>
							{
								this.state.hasCompletedSplit ?
								<div className="message">
                                	You're all set!
                            	</div>
								:
								<div className="message">
                                	This Live Split {this.state.wasInSplit?"has been closed.":"doesn't exist :("}
                            	</div>
							}

							{this.state.hasCompletedSplit &&
								<div className="submessage">
									Once {this.state.splitterName} completes this Split you'll receive a text indicating your portion of the bill. It will include both tip and taxes.
								</div>
							}

                            <div className="marketing">
                            	To start saving on your next group outing and to enjoy all the features of Split, download it on the App Store.
                            </div>
                            <Box src={{}}>
                                <Link href="https://apple.co/3bfxaKs" target="_blank">
                                    <AppStoreLogo />
                                </Link>
                                
                            </Box>                  
                    </div>
				}			
			</>
		);
	}
}


export default LiveSplitView