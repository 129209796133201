import React, {Component}  from 'react';
import {Grid, Paper, Box, Avatar, Link, AvatarGroup} from '@mui/material';
import { deepOrange, deepPurple } from '@mui/material/colors';
import { useParams } from "react-router-dom";
import { ReactComponent as AppStoreLogo } from './images/app_store_black.svg';
import background from './images/Splash_Screen.png';
import oval from './images/Oval.png';
import logo from './images/site_logo_only.png';
import logoClean from './images/CleanLogoWithName.png';
import logoSelect from './images/Group_34.png';
import separator from './images/site_image_separator.png';
import './livesplitTest.css';
import * as DEBUG from './debug'; 

class LivesplitTest extends Component{
	constructor(props){
		super(props);

		this.state = {
			itemList : [
                {
                    id:'1',
                    name:'pasta',
                    assignees:[
                        'A','B','C','D','E','F','G','H','I'
                    ]
                },
                {
                    id:'2',
                    name:'salad',
                    assignees:[
                        'A','B','C'
                    ]
                },
                {
                    id:'3',
                    name:'ceasar',
                    assignees:[
                        'A','B','C'
                    ]
                },
                {
                    id:'4',
                    name:'cheese',
                    assignees:[
                        'A','B','C'
                    ]
                },
                {
                    id:'5',
                    name:'drink',
                    assignees:[
                        'A','B','C'
                    ]
                },
                {
                    id:'6',
                    name:'bread',
                    assignees:[
                        'A','B','C'
                    ]
                },
                {
                    id:'7',
                    name:'dessert',
                    assignees:[
                        'A','B','C'
                    ]
                },
                {
                    id:'8',
                    name:'coffee',
                    assignees:[
                        'A','B','C'
                    ]
                },  
                {
                    id:'9',
                    name:'drinks',
                    assignees:[
                        'A','B','C'
                    ]
                },                               
            ],
			isConnected:false,
			myId:null,
			room:null,
			listAttendees:{
                'A':'FV',
                'B':'JA',
                'C':'3312',
                'D':'KP',
                'E':'LO',
                'F':'AH',
                'G':'RT',
                'H':'RP',
                'I':'MR', 
            },
			listColors:{
                'A':'a1a1a1'
            },
            notEmpty:true,
            wasInSplit:true,
			splitterName:'Fabien',
			hasCompletedSplit:true,            
		};
	}


	componentDidMount = () =>{
		DEBUG.consoleFunctionSystem("componentDidMount");
	
	}

	componentDidUpdate = (prevProps, prevState) =>{
		DEBUG.consoleFunctionSystem("componentDidUpdate");

	}

	componentWillUnmount() {
		DEBUG.consoleFunctionSystem("componentWillUnmount");

	  }

    handleDoneClick = (e) => {
        DEBUG.consoleFunctionSystem("HandleDoneClick");

		this.setState({
			hasCompletedSplit:true,
		});        
    }

    handleClick = (e, itemId, itemIndex) => {
		DEBUG.consoleFunctionSystem("HandleClick");

		console.log("handleClick ",itemId,itemIndex);

        let updatedList = [...this.state.itemList];    // make a copy of the existing list
		console.log("updatedList ", updatedList);

        let updatedItem = {...this.state.itemList[itemIndex]}; // make a copy of the item that will be updated
		console.log("updatedItem ", updatedItem);

        let action = "";

		// does the list of assignees have the current user?
		let assigneeIndex = updatedItem.assignees.find(element => element === this.state.myId);

		// if not undefined, we found our user, we remove them
		if(assigneeIndex){
            updatedItem.assignees.splice(assigneeIndex,1);
            action = "remove";
		}
		else{
            updatedItem.assignees.push(this.state.myId);
            action = "add";
		}

        let obj = {
			room:this.state.room,
			message:{
				itemId:itemId,
				parentId:updatedItem.parentId,
				userId:this.state.myId,
				action:action,
				timestamp:Date.parse(new Date())/1000,
			}
        };

		console.log("liveupdated obj ", obj);

		updatedList[itemIndex]= updatedItem;
		console.log("updatedList ", updatedList);

		this.setState({
			itemList:updatedList,
		})
        
		console.log("handleClick DONE");
    }	


	render(){
		DEBUG.consoleFunctionSystem("Render");

		return (
			<>
				{   this.state.notEmpty ?
                    <div className="mainPage">
                        <img src={logoSelect} className="topLogo"/>

                        <div className="title">
                            Welcome to Fabien's Live Split!
                        </div>

                        <div className='instructions'>
                            Tap each item to select what you ordered. If an item was shared, it can be selected by multiple people
                        </div>
                        
                        {/* <img src={separator} className="separatorImage"/> */}
                        <Grid container spacing={1} className="gridClass">
                            {this.state.itemList.map((item, index) =>(
                                <Grid item xs={12} key={'row_'+item.id}>
                                    <Box
                                        sx={{
                                        bgcolor: 'background.paper',
                                        boxShadow: 1,
                                        borderRadius: 2,
                                        p: 2,
                                        minWidth: 300,
                                        textAlign:'left',
                                        }}

                                        className='cardTest'

                                        onClick={(e)=>this.handleClick(e,item.id, index)}
                                    >
                                        <Box sx={{ color: 'text.secondary', fontFamily:'Avenir-Medium', fontSize:'16px'}}>{item.name}</Box>
                                        <div className="avatarDisplay">
                                            <AvatarGroup max={4}>
                                                {item.assignees.length > 0 && item.assignees.map((assignee,index)=>(
                                                    <Avatar key={'avatar_'+assignee}
                                                        sx={{ bgcolor: '#'+this.state.listColors[assignee],
                                                        fontSize:`${this.state.listAttendees[assignee].length > 2 ? "1.rem" : "1.25rem"}`
                                                     }}>
                                                        {this.state.listAttendees[assignee]}
                                                    </Avatar>
                                                ))
                                                }
                                            </AvatarGroup>
                                        </div>
                                    </Box>
                                </Grid>
                            ))}
                        </Grid>

                        <footer class="button-bar" onClick={this.handleDoneClick}>
                            <button class="button">Done</button>
                        </footer>
                    </div>
                    :
                    <div style={{
                        backgroundImage:`url(${background})`,
                        height:'100vh', 
                        backgroundRepeat: 'no-repeat',
                        backgroundSize: 'cover',
                        backgroundPosition: 'center'
                        }}>
                            <img src={logoClean} className="topLogoSplash"/>
                            {
								this.state.hasCompletedSplit ?
								<div className="message">
                                	You're all set!
                            	</div>
								:
								<div className="message">
                                	This Live Split {this.state.wasInSplit?"has been closed.":"doesn't exist :("}
                            	</div>
							}

							{this.state.hasCompletedSplit &&
								<div className="submessage">
									Once {this.state.splitterName} completes this Split you'll receive a text indicating your portion of the bill. It will include both tip and taxes.
								</div>
							}
                            <Box src={{}}>
                                <Link href="https://apple.co/3bfxaKs" target="_blank">
                                    <AppStoreLogo />
                                </Link>
                                
                            </Box>
                    </div>
				}			
			</>
		);
	}
}


export default LivesplitTest