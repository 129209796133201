import React, {Component}  from 'react';
import {TextField, Grid, Paper, Box, Avatar, AvatarGroup} from '@mui/material';
import { deepOrange, deepPurple } from '@mui/material/colors';
import io from 'socket.io-client';
import { ReactComponent as AppStoreLogo } from './images/app_store_black.svg';
import background from './images/Splash_Screen.png';
import logo from './images/split_logo_live.png';
import logoClean from './images/CleanLogoWithName.png';
import logoSelect from './images/Group_34.png';
import './splitstatus.css';
import * as DEBUG from './debug'; 

// console.log("Just before socket init");
const socket = io();


class SplitStatusView extends Component{
	constructor(props){
		super(props);

		this.state = {
            socketList:[],
		};
	}


	componentDidMount = () =>{
		DEBUG.consoleFunctionSystem("componentDidMount");
	
        socket.on('connect', () => {
            DEBUG.consoleFunctionAPI("connect");

            socket.emit('getStatus', '', (response) => {
                DEBUG.consoleFunctionAPI("getStatus");
                console.log(response);

                this.setState({
                    socketList:response.list,
                })

            });
			
        });			
	}

    handleDoneClick = (e) => {
		DEBUG.consoleFunctionSystem("handleDoneClick");

    }	

	render(){
		DEBUG.consoleFunctionSystem("Render");

		return (
			<>
				<div className="mainPage">

                <Grid container className="roomTable">
                    <Grid container className="roomHeader">
                        <Grid item>Room</Grid>
                        <Grid item># Users</Grid>
                    </Grid>
                {
                    this.state.socketList.map((item,index) => (
                        <Grid container className="roomRow">
                            <Grid item key={'row_a'+index} className="roomCella">
                                {item.room}
                            </Grid>
                            <Grid item key={'row_b'+index} className="roomCellb">
                                {item.count}
                            </Grid>
                        </Grid>

                    ))
                }
                </Grid>
					
				</div>
			</>
		);
	}
}

export default SplitStatusView