import React, {Component}  from 'react';
import {TextField, Grid, Paper, Box, Avatar, AvatarGroup} from '@mui/material';
import { deepOrange, deepPurple } from '@mui/material/colors';
import { useParams, useNavigate } from "react-router-dom";
import io from 'socket.io-client';
import { ReactComponent as AppStoreLogo } from './images/app_store_black.svg';
import background from './images/Splash_Screen.png';
import logo from './images/split_logo_live.png';
import logoClean from './images/CleanLogoWithName.png';
import logoSelect from './images/Group_34.png';
import './addFriendsView.css';
import * as DEBUG from './debug'; 

// console.log("Just before socket init");
const socket = io();


class LiveSplitView extends Component{
	constructor(props){
		super(props);

		this.state = {
			isConnected:false,
			splitterName:'',
			room:null,
			name:'',
			phone:'',
			myid:randomString(5)
		};
	}


	componentDidMount = () =>{
		DEBUG.consoleFunctionSystem("componentDidMount");
		
		let urlStr = window.location.href;
		let room = urlStr.indexOf('localhost') === -1 ? urlStr.split("livesplit/addFriend/")[1] : "";

        socket.on('connect', () => {
            DEBUG.consoleFunctionAPI("connect");

			if(!this.state.isConnected){
				console.log("Not connected, joining room"+this.state.room);

				socket.emit('joinRoom', this.state.room, '', (response) => {
					console.log(response);
	
					this.setState({
						isConnected:response === "success",
					});
				});
			}
        });	
		
        socket.on('disconnect', () => {
            DEBUG.consoleFunctionAPI("disconnect");

			this.setState({
				isConnected:false,
			});
            console.log('disconnected');
        });

		this.setState({		
			room:room,			
		})		
	}

	componentDidUpdate = (prevProps, prevState) =>{
		DEBUG.consoleFunctionSystem("componentDidUpdate");
	}

	componentWillUnmount() {
		DEBUG.consoleFunctionSystem("componentWillUnmount");
		socket.off("initList");
		socket.off("liveUpdate");
	}

    handleDoneClick = (e) => {
		DEBUG.consoleFunctionSystem("handleDoneClick");

        let obj = {
			friendName:this.state.name,
			friendPhone:this.state.phone.replace('(','').replace(')','').replace(' ','').replace('-',''),
			friendId:this.state.myid,
			room:this.state.room,
        };

		console.log("newFriend obj ", obj);
        socket.emit("newFriend",obj);

		console.log("handleClick DONE. Now navigating away...");

		this.props.navigate('/livesplit/'+this.state.room+'?myId='+this.state.myid);
    }	

	handleChange = (e,stateKey) =>{
		let newState = {};
		newState[stateKey] = e.target.value;

		this.setState(newState);
	}


	render(){
		DEBUG.consoleFunctionSystem("Render");

		return (
			<>
				<div className="mainPage">
					<img src={logoSelect} alt="" className="topLogo"/>

					<div className="intro">
						Help the Splitter identify you by providing your information
					</div>

					<TextField
						required 
						id="name"
						label="Name"
						variant="outlined"
						className="textName"
						value={this.state.name}
						onChange={(e)=>this.handleChange(e,'name')}
					/> 
					<br/>

					<TextField
						required id="phone"
						label="Phone"
						variant="outlined"
						className="textPhone"
						value={this.state.phone}
						onChange={(e)=>this.handleChange(e,'phone')}
					/>

					<footer class="button-bar" onClick={this.handleDoneClick}>
						<button class="button">Done</button>
					</footer>
					
				</div>
			</>
		);
	}
}

function withRouter(Component) {
	function ComponentWithRouterProp(props) {
		let navigate = useNavigate();
		let params = useParams();
		return (
		<Component
			{...props}
			navigate={navigate}
		/>
		);
	}

	return ComponentWithRouterProp;
}

function randomString(length){
	let letters = "abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789";
	
	return (letters.split("").map((l)=>letters.charAt(randomInt(letters.length))).reduce((acc,curr) => acc+curr,'')).slice(0,length);
  }
  
function randomInt(max){
	return Math.floor(Math.random() * max);
  }


export default withRouter(LiveSplitView)